<template>
  <LeaderLinesManager
    class="smart-search-query e-flex"
    :lines="linesConfigs"
    :class="{
      'smart-search-query--dense': dense,
    }"
  >
    <SmartSearchQueryComponent
      v-for="(component, index) in components"
      :key="component.id"
      :component="component"
      :predecessor="components[index - 1]"
      :is-readonly="isReadonly"
      :dense="dense"
      @connect-right="$emit('connect-right', $event)"
      @change="$emit('change', $event)"
      @delete-component="$emit('delete-component', $event)"
    />
  </LeaderLinesManager>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useSiteAnalyticsStore } from "@/stores/siteAnalytics"
import { useSmartSearchStore } from "@/stores/smartSearch"
import { PERMISSIONS } from "@/constants/permissions"
import SmartSearchQueryComponent from "@/components/siteAnalytics/SmartSearchQueryComponent.vue"
import LeaderLinesManager from "@evercam/shared/components/LeaderLinesManager.vue"
import {
  LeaderLinePlugType,
  LeaderLinesPathType,
  LeaderLinesSocketPosition,
  LineConfig,
  type SmartSearchOptionData,
  type SmartSearchQueryComponentData,
  SmartSearchQueryComponentType,
  SmartSearchQueryPart,
} from "@evercam/shared/types"

export default Vue.extend({
  meta: {
    requiredPermissions: [PERMISSIONS.SITE_ANALYTICS.VIEW],
  },
  name: "SmartSearchQuery",
  components: {
    LeaderLinesManager,
    SmartSearchQueryComponent,
  },
  props: {
    components: {
      type: Array as PropType<SmartSearchQueryComponentData[]>,
      required: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useAccountStore, useSiteAnalyticsStore, useSmartSearchStore),
    horizontalLineConfigs(): LineConfig[] {
      const lines: LineConfig[] = []

      return this.components.reduce((acc, component, index) => {
        if (index >= this.components.length - 1) return acc

        const nextComponent = this.components[index + 1]

        component?.parts.forEach((part, index) => {
          if (part.type === SmartSearchQueryComponentType.Operator) return

          acc.push({
            start: this.getSocketSelector(
              this.getQueryPartId(part),
              LeaderLinesSocketPosition.Right
            ),
            end: this.getSocketSelector(
              this.getQueryPartId(nextComponent.parts[0]),
              LeaderLinesSocketPosition.Left
            ),
            options: {
              color: "#c0ccde",
              path:
                index === 0 &&
                !Array.isArray(part) &&
                !Array.isArray(nextComponent.parts[0])
                  ? LeaderLinesPathType.Straight
                  : LeaderLinesPathType.Fluid,
              startPlug: LeaderLinePlugType.Behind,
              endPlug: LeaderLinePlugType.Arrow3,
              startPlugSize: 1.5,
              endPlugSize: 1.5,
              startSocketGravity: [70, 0],
              endSocketGravity: [-70, 0],
            },
          })
        })

        return acc
      }, lines)
    },
    verticalLineConfigs(): LineConfig[] {
      const lines: LineConfig[] = []

      this.components.forEach((component) => {
        const processPartConnections = (
          currentPart: SmartSearchQueryPart,
          nextPart: SmartSearchQueryPart | undefined,
          acc: LineConfig[]
        ) => {
          if (Array.isArray(currentPart)) {
            currentPart.forEach((part, index) => {
              if (index < currentPart.length - 1) {
                processPartConnections(part, currentPart[index + 1], acc)
              } else if (nextPart) {
                processPartConnections(part, nextPart, acc)
              }
            })
          } else if (nextPart && Array.isArray(nextPart)) {
            acc.push(this.createVerticalLineConfig(currentPart, nextPart[0]))
          } else if (nextPart && !Array.isArray(nextPart)) {
            acc.push(this.createVerticalLineConfig(currentPart, nextPart))
          }
        }

        component.parts.forEach((part, index) => {
          const nextPart =
            index < component.parts.length - 1
              ? component.parts[index + 1]
              : undefined
          processPartConnections(part, nextPart, lines)
        })
      })

      return lines
    },
    linesConfigs(): LineConfig[] {
      return [...this.horizontalLineConfigs, ...this.verticalLineConfigs]
    },
  },
  methods: {
    isOperator(part: SmartSearchOptionData): boolean {
      return part.type === SmartSearchQueryComponentType.Operator
    },
    getSocketSelector(id: string, position: LeaderLinesSocketPosition) {
      return `[data-id="${id}"] > .smart-search__query-component__part__socket--${position}`
    },
    getQueryPartId(
      part: SmartSearchOptionData | SmartSearchOptionData[]
    ): string {
      if (Array.isArray(part)) {
        return part.reduce((acc, p) => `${acc}-${p.id}`, "g")
      }

      return part.id
    },
    createVerticalLineConfig(
      start: SmartSearchOptionData,
      end: SmartSearchOptionData
    ): LineConfig {
      return {
        start: this.getSocketSelector(
          this.getQueryPartId(start),
          LeaderLinesSocketPosition.Bottom
        ),
        end: this.getSocketSelector(
          this.getQueryPartId(end),
          LeaderLinesSocketPosition.Top
        ),
        options: {
          color: "#c0ccde",
          path: LeaderLinesPathType.Straight,
          startPlug: LeaderLinePlugType.Behind,
          endPlug: this.isOperator(end)
            ? LeaderLinePlugType.Behind
            : LeaderLinePlugType.Arrow3,
          startPlugSize: 1.5,
          endPlugSize: 1.5,
        },
      }
    },
  },
})
</script>

<style lang="scss">
.smart-search-query {
  gap: 5rem;
  &--dense {
    gap: 1.5rem;
  }
}
</style>
