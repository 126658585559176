import {
  SmartSearchQuery,
  SmartSearchQueryComponentData,
  SmartSearchQueryComponentType,
  SmartSearchQueryTargets,
  SmartSearchQueryConditionTypes,
  SmartSearchOperatorType,
  SmartSearchQueryOperators,
  SmartSearchQueryReturnType,
} from "@evercam/shared/types"

export function convertToSmartSearchQuery({
  components,
  options = {},
}: {
  components: SmartSearchQueryComponentData[]
  options: {
    returnTargets?: SmartSearchQueryTargets[]
    rulesLogic?: SmartSearchOperatorType
    returnType?: SmartSearchQueryReturnType
  }
}) {
  const {
    returnTargets = [SmartSearchQueryTargets.Tracking],
    rulesLogic = SmartSearchOperatorType.And,
    returnType = SmartSearchQueryReturnType.FirstLastSeen,
  } = options

  const query: SmartSearchQuery = {
    returnTargets,
    rulesLogic,
    rules: [],
    returnType,
  }

  const processedComponentIds = new Set()

  const orGroups = components.filter(
    (obj) =>
      obj.type === SmartSearchQueryComponentType.Object &&
      obj.parts.some(
        (p) =>
          p.type === SmartSearchQueryComponentType.Operator &&
          p.value === SmartSearchOperatorType.Or
      )
  )

  const andGroups = components.filter(
    (obj) =>
      obj.type === SmartSearchQueryComponentType.Object &&
      obj.parts
        .flat()
        .some(
          (p) =>
            p.type === SmartSearchQueryComponentType.Operator &&
            p.value === SmartSearchOperatorType.And
        )
  )

  const timeGroups = components.filter(
    (obj) => obj.type === SmartSearchQueryComponentType.Time
  )

  orGroups.forEach((group) => {
    const orRule = {
      logic: SmartSearchOperatorType.Or,
      conditions: [],
    }

    group.parts.forEach((part) => {
      if (part.type !== SmartSearchQueryComponentType.Object) {
        return
      }

      orRule.conditions.push({
        type: SmartSearchQueryConditionTypes.Selection,
        target: SmartSearchQueryTargets.Tracking,
        attribute: "label",
        operator: "=",
        value: part.value,
      })
    })

    if (orRule.conditions.length === 0) {
      return
    }

    query.rules.push(orRule)
    processedComponentIds.add(group.id)
  })

  andGroups.forEach((group) => {
    const andRule = {
      logic: SmartSearchOperatorType.And,
      conditions: [],
    }

    group.parts[0].forEach((part) => {
      if (part.type !== SmartSearchQueryComponentType.Object) {
        return
      }

      andRule.conditions.push({
        type: SmartSearchQueryConditionTypes.Selection,
        target: SmartSearchQueryTargets.Tracking,
        attribute: "label",
        operator: "=",
        value: part.value,
      })
    })

    if (andRule.conditions.length === 0) {
      return
    }

    query.rules.push(andRule)
    processedComponentIds.add(group.id)
  })

  const andRule = {
    logic: "AND",
    conditions: [],
  }

  components.forEach((component, i) => {
    const nextComponent = i < components.length - 1 ? components[i + 1] : null

    if (
      processedComponentIds.has(component.id) ||
      component.type !== SmartSearchQueryComponentType.Condition ||
      component.parts[0].type !== SmartSearchQueryComponentType.Condition ||
      component.parts[0].value !== SmartSearchQueryOperators.Area ||
      !nextComponent ||
      nextComponent.type !== SmartSearchQueryComponentType.Area
    ) {
      return
    }

    andRule.conditions.push({
      type: SmartSearchQueryConditionTypes.Area,
      target: SmartSearchQueryTargets.Tracking,
      operator: SmartSearchQueryOperators.Inside,
      polygon: nextComponent.parts[0].value,
    })

    processedComponentIds.add(component.id)
    processedComponentIds.add(nextComponent.id)
  })

  components.forEach((component, i) => {
    const prevComponent = i > 0 ? components[i - 1] : null
    const nextComponent = i < components.length - 1 ? components[i + 1] : null

    if (processedComponentIds.has(component.id)) {
      return
    }

    if (
      component.type !== SmartSearchQueryComponentType.Condition ||
      component.parts[0].type !== SmartSearchQueryComponentType.Condition ||
      component.parts[0].value !== SmartSearchQueryOperators.Intersects ||
      !nextComponent ||
      nextComponent.type !== "object" ||
      processedComponentIds.has(nextComponent.id)
    ) {
      return
    }

    andRule.conditions.push({
      type: SmartSearchQueryConditionTypes.Spatial,
      operator: SmartSearchQueryOperators.Intersects,
      target: SmartSearchQueryTargets.Tracking,
      reference: {
        target: SmartSearchQueryTargets.Tracking,
        attribute: "label",
        value: nextComponent.parts[0].value,
      },
    })

    if (
      !prevComponent ||
      prevComponent.type !== SmartSearchQueryComponentType.Object ||
      processedComponentIds.has(prevComponent.id) ||
      prevComponent.parts.some(
        (p) => p.type === SmartSearchQueryComponentType.Operator
      )
    ) {
      processedComponentIds.add(component.id)
      processedComponentIds.add(nextComponent.id)

      return
    }

    andRule.conditions.push({
      type: SmartSearchQueryConditionTypes.Selection,
      target: SmartSearchQueryTargets.Tracking,
      attribute: "label",
      operator: "=",
      value: prevComponent.parts[0].value,
    })

    processedComponentIds.add(prevComponent.id)
    processedComponentIds.add(component.id)
    processedComponentIds.add(nextComponent.id)
  })

  components.forEach((component) => {
    if (
      processedComponentIds.has(component.id) ||
      component.type !== "object" ||
      component.parts.some(
        (p) => p.type === SmartSearchQueryComponentType.Operator
      )
    ) {
      return
    }

    andRule.conditions.push({
      type: SmartSearchQueryConditionTypes.Selection,
      target: SmartSearchQueryTargets.Tracking,
      attribute: "label",
      operator: "=",
      value: component.parts[0].value,
    })

    processedComponentIds.add(component.id)
  })

  if (andRule.conditions.length > 0) {
    query.rules.push(andRule)
  }

  let payload: Record<string, unknown> = { req: query }

  if (timeGroups.length > 0) {
    payload = { req: query, timeSchedule: timeGroups[0].parts[0]?.value }
  }

  console.log(JSON.stringify(payload, null, 2))

  return payload
}
