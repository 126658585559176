<template>
  <v-hover v-slot="{ hover }" @input="handleThumbnailHover">
    <div ref="smartSearchThumbnail" class="e-relative e-w-full e-h-16">
      <img
        v-show="!isLoadingThumbnail"
        class="e-w-full e-h-full e-object-cover smart-search-item__thumbnail"
        :src="thumbnailUrl"
        @load="isLoadingThumbnail = false"
        @error="isLoadingThumbnail = false"
      />
      <v-fade-transition>
        <div
          v-show="hover && !isLoadingThumbnail"
          class="smart-search-item__thumbnail__play-btn"
        >
          <SnapshotPreview
            :camera="siteAnalyticsStore.selectedCamera"
            :title="title"
            class="w-100 h-100 previewable-thumbnail__player"
            :timestamp="timestamp"
            :auth-token="accountStore.token"
            show-snapshot-quality
            :show-selectable-cameras="false"
            :show-recordings-button="false"
            :start-date="startEndDate.start"
            :end-date="startEndDate.end"
          >
            <template #custom-button>
              <v-btn fab x-small>
                <v-icon x-small> fas fa-play </v-icon>
              </v-btn>
            </template>
          </SnapshotPreview>
        </div>
      </v-fade-transition>
      <div
        v-if="isLoadingThumbnail"
        class="smart-search-item__thumbnail__loader"
      >
        <EvercamLoadingAnimation size="TwoXl" />
      </div>
    </div>
  </v-hover>
</template>

<script lang="ts">
import Vue from "vue"

import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"
import { base64UrlEncode } from "@evercam/shared/utils"
import axios from "@evercam/api/api/client"
import SnapshotPreview from "@evercam/shared/components/SnapshotPreview.vue"
import { useSiteAnalyticsStore } from "@/stores/siteAnalytics"
export default Vue.extend({
  name: "SmartSearchThumbnailPreview",
  components: {
    EvercamLoadingAnimation,
    SnapshotPreview,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    timestamp: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoadingThumbnail: true,
      isLoadingImage: true,
      isDialogOpened: false,
      playerHeight: 0,
    }
  },
  computed: {
    ...mapStores(useAccountStore, useSiteAnalyticsStore),
    snapshotUrl(): string {
      const snapshotsEndpoint = `${this.$config.public.apiURL}/cameras/${this.siteAnalyticsStore.selectedCameraExid}/recordings/snapshots`
      const processedTimestamp = this.$moment(this.timestamp).format()

      return `${snapshotsEndpoint}/${processedTimestamp}?view=true&authorization=${this.accountStore.token}`
    },
    title(): string {
      return `${this.siteAnalyticsStore.selectedCameraExid} - ${
        this.item.label
      } - ${this.formatTimestamp(this.timestamp)}`
    },
    startEndDate(): Record<string, string> {
      const intervalDuration =
        new Date(this.item.lastSeen).getTime() -
        new Date(this.item.firstSeen).getTime()
      const padding = intervalDuration / 4

      const playerStartDate = new Date(this.item.firstSeen).getTime() - padding
      const playerEndDate = new Date(this.item.lastSeen).getTime() + padding

      return {
        start: new Date(playerStartDate).toISOString(),
        end: new Date(playerEndDate).toISOString(),
      }
    },
    bbox(): number[] {
      if (
        !this.item.bbox.coordinates ||
        !this.item.bbox.coordinates.length ||
        !this.item.bbox.coordinates[0].length
      ) {
        console.error("Invalid this.item.bbox format")

        return [0, 0, 0, 0]
      }

      const polygon = this.item.bbox.coordinates[0]
      let xMin = polygon[0][0]
      let yMin = polygon[0][1]
      let xMax = polygon[0][0]
      let yMax = polygon[0][1]
      for (const [x, y] of polygon) {
        xMin = Math.min(xMin, x)
        yMin = Math.min(yMin, y)
        xMax = Math.max(xMax, x)
        yMax = Math.max(yMax, y)
      }

      return [xMin, yMin, xMax, yMax] as number[]
    },
    thumbnailUrl(): string {
      return this.getCroppedImageUrl(this.snapshotUrl)
    },
  },
  watch: {
    isDialogOpened: {
      async handler(value) {
        if (value) {
          this.updatePlayerDimensions()
        }
      },
      immediate: true,
    },
  },

  methods: {
    handleThumbnailHover(isHovered) {
      const thumbnail = this.$refs.smartSearchThumbnail as HTMLElement
      this.$emit("thumbnail-hovered", {
        isHovered,
        url: this.item.thumbnailUrl,
        targetRect: thumbnail.getBoundingClientRect(),
      })
    },
    getCroppedImageUrl(url, suffix = true) {
      const filePath = base64UrlEncode(url)

      const [xMin, yMin, xMax, yMax] = this.bbox
      let width = xMax - xMin
      let height = yMax - yMin
      const original_width = xMax - xMin
      const original_height = yMax - yMin
      const aspectRatio = 16 / 9

      if (width > height) {
        height = width / aspectRatio
      } else {
        width = height * aspectRatio
      }

      return `${axios.env.snapshotsURL}/sig/gravity:fp:${
        xMin + original_width / 2
      }:${
        yMin + original_height / 2
      }/crop:${width}:${width}/size:${720}:99999/${filePath}${
        suffix ? ".jpeg" : ""
      }`
    },
    formatTimestamp(timestamp) {
      if (!timestamp) return "—"

      return this.$moment(timestamp).format("ddd, DD MMMM YYYY, hh:mm:ss A")
    },
    updatePlayerDimensions() {
      if (!this.isDialogOpened) {
        return
      }

      const containerTop = this.$vuetify.breakpoint.smAndDown ? 70 : 45
      this.playerHeight = window.innerHeight * 0.8 - containerTop

      this.$nextTick(() => {
        const dialogEl = this.$refs.dialog.$el
        if (dialogEl) {
          const dialog = dialogEl.querySelector(".v-dialog")
          if (dialog) {
            dialog.style.overflow = "hidden"
          }

          const dialogContent = dialogEl.querySelector(".v-dialog__content")
          if (dialogContent) {
            dialogContent.style.overflowY = "hidden"
          }
        }
      })
    },
  },
})
</script>

<style scoped lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.smart-search-item {
  &__thumbnail {
    position: relative;
    height: 6rem;
    width: 100%;
    object-fit: cover;
    border-radius: 0;
    overflow: hidden;
    display: block;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    &:hover img {
      transform: scale(1.05);
    }

    &__loader,
    &__play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.previewable-thumbnail {
  border-radius: 0;
  background: transparent;
  overflow: hidden;

  &__content {
    position: relative;
    overflow: hidden;

    .timeline-container {
      padding: 1em 0;
      &:hover {
        .timeline {
          padding: 0.3em;
        }
      }
      .timeline {
        height: 6px;
        opacity: 0.8;
      }
    }
  }

  &__player {
    .player {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  .smart-search-item__thumbnail__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
  .smart-search__image__loader {
    min-height: 500px;
    background: var(--v-background-base);
  }
}

::v-deep .custom-toolbar .v-toolbar__content {
  line-height: 45px !important;
}

.snapshot-player-dialog {
  z-index: 999 !important;

  ::v-deep .v-dialog {
    overflow: hidden !important;
  }

  ::v-deep .v-card {
    display: flex;
    flex-direction: column;
    max-height: 85vh;
  }

  ::v-deep .v-card__text {
    flex: 1;
    overflow: hidden;
  }

  ::v-deep .v-dialog__content {
    overflow-y: hidden !important;
  }
}
</style>

<style>
.snapshot-dialog-content .v-dialog {
  overflow: hidden !important;
}
</style>
