<template>
  <div
    class="smart-search__query-component__part e-inline-flex e-flex-col e-items-center e-justify-center e-relative"
  >
    <!-- Socket Top -->
    <div
      v-show="!isReadonly"
      class="smart-search__query-component__part__socket smart-search__query-component__part__socket--top e-absolute e-top-0"
    ></div>

    <!-- Socket Left -->
    <div
      v-show="!isReadonly"
      class="smart-search__query-component__part__socket smart-search__query-component__part__socket--left"
    ></div>

    <div
      v-if="Array.isArray(part)"
      class="parts-group e-flex e-flex-col e-items-center e-rounded-lg e-p-3"
    >
      <SmartSearchQueryComponentPart
        v-for="p in part"
        :key="p.id"
        :data-id="p.id"
        :part="p"
        :allow-connection-right="false"
        :allow-connection-bottom="false"
        @change="emitChange($event)"
      />
    </div>

    <template v-else>
      <!-- Operator -->
      <SmartSearchQueryOperator
        v-if="isOperator(part)"
        :value="part.value"
        :type="part.type"
        @input="onOperatorChange"
      />
      <!-- Option Chip -->
      <SmartSearchOption
        v-else
        :value="part.value"
        :type="part.type"
        :dense="dense"
        :deletable="!isReadonly"
        @delete-option="$emit('delete-option', part)"
        @click.native="onOptionClick(part)"
      />
    </template>

    <!-- Socket Right -->
    <div
      v-show="!isReadonly"
      class="smart-search__query-component__part__socket smart-search__query-component__part__socket--right"
    >
      <EIcon
        v-if="!hasConnectionRight && allowConnectionRight"
        icon="plus"
        size="xs"
        class="e-rounded-full e-bg-primary e-text-white e-inline-flex e-p-1"
        @click.native="$emit('connect-right')"
      />
    </div>

    <!-- Socket Bottom -->
    <div
      v-show="!isReadonly"
      class="smart-search__query-component__part__socket smart-search__query-component__part__socket--bottom"
    >
      <EIcon
        v-if="!hasConnectionBottom && allowConnectionBottom"
        icon="plus"
        size="xs"
        class="e-rounded-full e-bg-primary e-text-white e-inline-flex e-p-1"
        @click.native="$emit('connect-bottom')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { mapStores } from "pinia"
import SmartSearchOption from "@/components/siteAnalytics/SmartSearchOption.vue"
import SmartSearchQueryOperator from "@/components/siteAnalytics/SmartSearchQueryOperator.vue"
import { useSmartSearchStore } from "@/stores/smartSearch"
import {
  SmartSearchCondition,
  SmartSearchOperatorType,
  SmartSearchOptionData,
  SmartSearchQueryComponentType,
} from "@evercam/shared/types"

export default Vue.extend({
  name: "SmartSearchQueryComponentPart",
  components: { SmartSearchQueryOperator, SmartSearchOption },
  props: {
    part: {
      type: [Object, Array] as PropType<
        SmartSearchOptionData | SmartSearchOptionData[]
      >,
      required: true,
    },
    hasConnectionBottom: {
      type: Boolean,
      default: false,
    },
    hasConnectionRight: {
      type: Boolean,
      default: false,
    },
    allowConnectionRight: {
      type: Boolean,
      default: true,
    },
    allowConnectionBottom: {
      type: Boolean,
      default: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapStores(useSmartSearchStore),
    partsArray(): SmartSearchOptionData[] {
      if (Array.isArray(this.part)) {
        return this.part
      }

      return [this.part]
    },
  },
  methods: {
    isOperator(part: SmartSearchOptionData): boolean {
      return part.type === SmartSearchQueryComponentType.Operator
    },
    emitChange(value: unknown) {
      if (typeof value === "object" && value !== null && "id" in value) {
        this.$emit("change", value as SmartSearchOptionData)
      } else {
        this.$emit("change", {
          ...this.part,
          value,
        })
      }
    },
    onOptionClick(part: SmartSearchOptionData) {
      let selectorType = part.type
      if (part.type === SmartSearchQueryComponentType.Condition) {
        this.smartSearchStore.openOptionSelectorDialog({
          optionSelectorType: SmartSearchQueryComponentType.Condition,
          selectedOption: part,
          onOptionSelected: this.emitChange,
        })

        return
      }

      if (
        part.type === SmartSearchQueryComponentType.Object ||
        part.type === SmartSearchQueryComponentType.Area ||
        part.type === SmartSearchQueryComponentType.Time
      ) {
        this.smartSearchStore.openOptionSelectorDialog({
          optionSelectorType: part.type,
          selectedOption: part,
          onOptionSelected: this.emitChange,
        })

        return
      }

      if (
        typeof part.value === "object" &&
        part.value !== null &&
        "condition" in part.value
      ) {
        const conditionData = part.value

        let optionSelectorType

        if (conditionData.condition === SmartSearchCondition.Time) {
          optionSelectorType = SmartSearchQueryComponentType.Time
        } else if (conditionData.condition === SmartSearchCondition.InArea) {
          optionSelectorType = SmartSearchQueryComponentType.Area
        } else if (
          conditionData.condition === SmartSearchCondition.Intersects
        ) {
          optionSelectorType = SmartSearchQueryComponentType.Object
        }

        this.smartSearchStore.openOptionSelectorDialog({
          optionSelectorType: optionSelectorType,
          selectedOption: {
            ...part,
            value: conditionData.value,
          },
          onOptionSelected: (newValue) => {
            this.emitChange({
              ...part,
              value: {
                ...conditionData,
                value: newValue,
              },
            })
          },
        })

        return
      }

      this.smartSearchStore.openOptionSelectorDialog({
        optionSelectorType: selectorType,
        selectedOption: part,
        onOptionSelected: this.emitChange,
      })
    },
    onOperatorChange(value: SmartSearchOperatorType) {
      this.emitChange(value)
    },
  },
})
</script>

<style lang="scss">
.smart-search__query-component__part {
  &__socket {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.2s;
    height: 1px;
    width: 1px;
    &--top {
      top: 0;
      .e-icon {
        transform: translate(-9px, -9px);
      }
    }
    &--right {
      right: 0;
      .e-icon {
        transform: translate(-9px, -9px);
      }
    }
    &--bottom {
      bottom: 0;
      .e-icon {
        transform: translate(-9px, -9px);
      }
    }
    &--left {
      left: 0;
      .e-icon {
        transform: translate(9px, 9px);
      }
    }
  }
  &:hover {
    .smart-search__query-component__part__socket {
      opacity: 1;
    }
  }
  .parts-group {
    border: 1px solid rgb(192, 204, 222);
    gap: 1.5rem;
    & > .smart-search__query-component__part:first-child {
      & > .smart-search__query-component__part__socket--top {
        transform: translateY(-0.75rem);
      }
    }
    & > .smart-search__query-component__part:last-child {
      & > .smart-search__query-component__part__socket--bottom {
        transform: translateY(0.75rem);
      }
    }
  }
}
</style>
