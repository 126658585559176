<template>
  <div class="leader-lines-manager">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import {
  type CssSelector,
  LeaderLinePlugType,
  LeaderLinesSocketPosition,
  type LineConfig,
} from "@evercam/shared/types"
export default Vue.extend({
  name: "LeaderLinesManager",
  props: {
    lines: {
      type: Array as () => LineConfig[],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeLines: [] as any[],
    }
  },
  watch: {
    lines: {
      handler: "refreshLines",
      deep: true,
    },
    disabled: "refreshLines",
  },
  mounted() {
    this.initLines()
    this.addScrollListener()
  },
  beforeDestroy() {
    this.clearLines()
    this.removeScrollListener()
  },
  methods: {
    initLines() {
      if (!window.LeaderLine) {
        this.$setTimeout(this.initLines, 100)

        return
      }
      this.drawLines()
    },
    drawLines() {
      if (this.disabled) {
        return
      }
      this.lines.forEach((lineConfig) => {
        const startElements = this.getDomElements(lineConfig.start)
        const endElements = this.getDomElements(lineConfig.end)
        startElements.forEach((startEl) => {
          if (!startEl) {
            return
          }
          endElements.forEach((endEl) => {
            if (!endEl) {
              return
            }
            const options = {
              size: 2,
              startSocket: LeaderLinesSocketPosition.Bottom,
              endSocket: LeaderLinesSocketPosition.Top,
              startPlug: LeaderLinePlugType.Disc,
              startPlugSize: 1.5,
              endPlug: LeaderLinePlugType.Disc,
              endPlugSize: 1.5,
              ...lineConfig.options,
            }
            if (typeof options.color === "function") {
              options.color = options.color(startEl)
            }
            if (typeof options.dash === "function") {
              options.dash = options.dash(startEl)
            }
            const line = new window.LeaderLine(startEl, endEl, options)
            this.activeLines.push(line)
          })
        })
      })
    },
    getDomElements(selectors: CssSelector): HTMLElement[] {
      const arraySelector = Array.isArray(selectors) ? selectors : [selectors]

      return arraySelector.reduce((acc, selector) => {
        return acc.concat(Array.from(this.$el.querySelectorAll(selector)))
      }, [])
    },
    clearLines() {
      try {
        this.activeLines.forEach((line) => line.remove())
        this.activeLines = []
      } catch (e) {
        console.error("Failed to clear leader lines:", e)
      }
    },
    refreshLines() {
      this.$setTimeout(() => {
        try {
          this.clearLines()
          this.drawLines()
        } catch (e) {
          console.error("Failed to refresh leader lines:", e)
          this.refreshLines()
        }
      }, 100)
    },
    repositionLines() {
      try {
        this.activeLines.forEach((line) =>
          requestAnimationFrame(() => line.position())
        )
      } catch (e) {
        console.error("Failed to reposition leader lines:", e)
      }
    },
    addScrollListener() {
      if (this.$globalRefs?.mainContent) {
        this.$addEventListener(
          "scroll",
          this.repositionLines,
          this.$globalRefs.mainContent
        )
      }
    },
    removeScrollListener() {
      if (this.$globalRefs?.mainContent) {
        this.$removeEventListener(
          "scroll",
          this.repositionLines,
          this.$globalRefs.mainContent
        )
      }
    },
  },
})
</script>
