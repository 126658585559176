<template>
  <div class="smart-search-query-component e-flex e-flex-col e-items-center">
    <SmartSearchQueryComponentPart
      v-for="(part, index) in parts"
      :key="getId(part)"
      :data-id="getId(part)"
      :part="part"
      :has-connection-right="hasConnectionRight"
      :has-connection-bottom="index < parts.length - 1 && parts.length > 1"
      :allow-connection-right="!isOperator(part)"
      :is-readonly="isReadonly"
      :dense="dense"
      @delete-option="onDeleteOption"
      @connect-right="$emit('connect-right')"
      @connect-bottom="onConnectBottom"
      @change="onPartChange($event)"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { mapStores } from "pinia"
import { useSmartSearchStore } from "@/stores/smartSearch"
import SmartSearchQueryComponentPart from "@/components/siteAnalytics/SmartSearchQueryComponentPart.vue"
import {
  SmartSearchOperatorType,
  type SmartSearchQueryComponentData,
  type SmartSearchOptionData,
  SmartSearchQueryPart,
  SmartSearchQueryComponentType,
} from "@evercam/shared/types"

type GroupRange = {
  start: number
  end: number
}

export default Vue.extend({
  name: "SmartSearchQueryComponent",
  components: {
    SmartSearchQueryComponentPart,
  },
  props: {
    component: {
      type: Object as PropType<SmartSearchQueryComponentData>,
      required: true,
    },
    predecessor: {
      type: [Object, undefined] as PropType<
        SmartSearchQueryComponentData | undefined
      >,
      default: undefined,
    },
    hasConnectionRight: {
      type: Boolean,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapStores(useSmartSearchStore),
    parts: {
      get(): SmartSearchQueryPart[] {
        return this.component.parts
      },
      set(newParts: SmartSearchQueryPart[]) {
        this.$emit("change", {
          ...this.component,
          parts: newParts,
        })
      },
    },
  },
  methods: {
    getId(part: SmartSearchQueryPart): string {
      if (Array.isArray(part)) {
        return part.reduce(
          (acc, p) => `${acc}-${(p as SmartSearchOptionData).id}`,
          "g"
        )
      }

      return part.id
    },
    isOperator(
      part: SmartSearchQueryPart,
      value?: SmartSearchOperatorType
    ): boolean {
      const isOperator =
        !Array.isArray(part) &&
        part.type === SmartSearchQueryComponentType.Operator

      if (isOperator && value) {
        return (part as SmartSearchOptionData).value === value
      }

      return isOperator
    },
    findAndUpdatePart(
      parts: SmartSearchQueryPart[],
      updatedPart: SmartSearchQueryPart
    ) {
      return parts.map((part) => {
        if (Array.isArray(part)) {
          return this.findAndUpdatePart(part, updatedPart)
        }
        if (part.id === (updatedPart as SmartSearchOptionData).id) {
          return updatedPart
        }

        return part
      })
    },
    onPartChange(updatedPart: SmartSearchQueryPart) {
      const updatedParts = this.findAndUpdatePart(this.parts, updatedPart)
      if (JSON.stringify(updatedParts) === JSON.stringify(this.parts)) {
        return
      }

      this.parts = this.reorganizeParts(updatedParts)
    },
    flattenParts(parts: SmartSearchQueryPart[]): SmartSearchOptionData[] {
      // @ts-ignore (see https://github.com/microsoft/TypeScript/issues/49280 )
      return JSON.parse(JSON.stringify(parts)).flat(Infinity)
    },
    reorganizeParts(parts: SmartSearchQueryPart[]): SmartSearchQueryPart[] {
      if (parts.length < 1) {
        return parts
      }

      const flatParts = this.flattenParts(parts)
      const groupRanges = this.findGroupRanges(flatParts)

      return this.buildGroupedResult(flatParts, groupRanges)
    },
    findGroupRanges(parts: SmartSearchQueryPart[]): GroupRange[] {
      return parts.reduce((acc: GroupRange[], part, index) => {
        const lastRange = acc[acc.length - 1]

        if (this.isOperator(part, SmartSearchOperatorType.And)) {
          if (lastRange?.end === index - 1) {
            lastRange.end = index + 1
          } else {
            acc.push({ start: index - 1, end: index + 1 })
          }
        }

        return acc
      }, [])
    },
    buildGroupedResult(
      parts: SmartSearchQueryPart[],
      ranges: GroupRange[]
    ): SmartSearchQueryPart[] {
      const result = ranges
        .reduce((result: SmartSearchQueryPart[], range, index) => {
          const start = index === 0 ? 0 : ranges[index - 1].end + 1
          const beforeGroup = parts.slice(start, range.start)
          const group = [parts.slice(range.start, range.end + 1)]

          return [...result, ...beforeGroup, ...group]
        }, [])
        .concat(parts.slice(ranges[ranges.length - 1]?.end + 1 || 0))

      return result
    },
    onConnectBottom() {
      this.smartSearchStore.openOptionSelectorDialog({
        optionSelectorType: SmartSearchQueryComponentType.Object,
        onOptionSelected: (value) => {
          const newParts: SmartSearchQueryPart = [
            {
              id: `${this.component.id}-${this.parts.length}`,
              type: SmartSearchQueryComponentType.Operator,
              value: SmartSearchOperatorType.Or,
            },
            {
              id: `${this.component.id}-${this.parts.length + 1}`,
              type: SmartSearchQueryComponentType.Object,
              value,
            },
          ]
          this.parts = this.reorganizeParts(this.parts.concat(newParts))
        },
      })
    },

    onDeleteOption(part) {
      this.$emit("delete-component", {
        componentId: this.component.id,
        partId: part.id,
      })

      const index = this.parts.findIndex((p) => {
        if (Array.isArray(p)) {
          return p.some((subPart) => subPart.id === part.id)
        }

        return p.id === part.id
      })

      if (index !== -1) {
        const updatedParts = this.parts.slice(0, index)

        if (updatedParts.length === 0) {
          this.$emit("delete-component", {
            componentId: this.component.id,
            deleteEntireComponent: true,
          })
        } else {
          this.parts = updatedParts
        }
      }
    },
  },
})
</script>

<style lang="scss">
.smart-search-query-component {
  gap: 1.5rem;
}
</style>
