<template>
  <EToggleSwitch
    v-model="operand"
    :options="options"
    size="sm"
    class="smart-search__operator"
    :dark="false"
  ></EToggleSwitch>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { SmartSearchOperatorType } from "@evercam/shared/types"

export default Vue.extend({
  name: "SmartSearchQueryOperator",
  props: {
    value: {
      type: String as PropType<SmartSearchOperatorType>,
      required: true,
    },
  },
  data() {
    return {
      options: [
        { label: "AND", value: SmartSearchOperatorType.And },
        { label: "OR", value: SmartSearchOperatorType.Or },
      ],
    }
  },
  computed: {
    operand: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit("input", v)
      },
    },
  },
})
</script>

<style lang="scss">
.smart-search__operator {
  &.e-bg-gray-900 {
    background: rgb(236 238 241) !important;
  }
  .e-toggle-switch {
    &.e-bg-brand-600 {
      background: rgba(59, 130, 246);
    }
    &:not(.e-bg-brand-600) {
      box-shadow: none !important;
      color: rgb(3 32 80 / 34%);
    }
  }
}
</style>
