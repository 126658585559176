<template>
  <div
    ref="container"
    v-resize-observer="setHeight"
    class="pa-4 px-8"
    :style="containerStyles"
  >
    <div
      class="grid-table__container e-relative e-w-full e-h-full e-flex e-flex-col e-overflow-y-auto e-overflow-x-hidden pr-2"
    >
      <!-- Table Headers -->
      <div
        v-if="items.length"
        class="grid-table grid-table__headers e-gap-2 e-bg-gray-100 e-p-2 e-font-bold e-text-gray-700 e-sticky e-top-0 e-border-solid e-border-b e-border-gray-200"
        :class="{
          'all-table-grid': returnTypeAll,
          'first-last-seen-table-grid': returnTypeFirstLastSeen,
        }"
      >
        <div v-if="returnTypeAll" class="e-flex e-justify-center">
          Thumbnail
        </div>
        <div v-if="returnTypeFirstLastSeen" class="e-flex e-justify-center">
          First Seen Thumbnail
        </div>
        <div v-if="returnTypeFirstLastSeen" class="e-flex e-justify-center">
          Last Seen Thumbnail
        </div>
        <div v-if="returnTypeAll" class="e-flex e-justify-center">
          Timestamp
        </div>
        <div v-if="returnTypeFirstLastSeen" class="e-flex e-justify-center">
          First Seen
        </div>
        <div v-if="returnTypeFirstLastSeen" class="e-flex e-justify-center">
          Last Seen
        </div>
        <div class="e-flex e-justify-center">Label</div>
        <div class="e-flex e-justify-center">Track ID</div>
      </div>

      <!-- Table Body -->
      <div class="e-divide-y e-divide-gray-200 grid-table__body">
        <div
          v-for="item in items"
          :key="getKey(item)"
          class="grid-table e-hover:e-bg-gray-50 e-items-center e-py-4 e-px-6 e-border-b e-border-solid e-border-gray-200 overflow-hidden"
          :class="{
            'all-table-grid': returnTypeAll,
            'first-last-seen-table-grid': returnTypeFirstLastSeen,
          }"
        >
          <div v-if="returnTypeAll" class="e-flex e-justify-center">
            <SmartSearchThumbnailPreview
              :item="item"
              :timestamp="item.timestamp"
            />
          </div>
          <div v-if="returnTypeFirstLastSeen" class="e-flex e-justify-center">
            <SmartSearchThumbnailPreview
              :item="item"
              :timestamp="item.firstSeen"
            />
          </div>
          <div v-if="returnTypeFirstLastSeen" class="e-flex e-justify-center">
            <SmartSearchThumbnailPreview
              :item="item"
              :timestamp="item.lastSeen"
            />
          </div>
          <div v-if="returnTypeAll" class="e-flex e-justify-center">
            {{ formatTimestamp(item.timestamp) }}
          </div>
          <div v-if="returnTypeFirstLastSeen" class="e-flex e-justify-center">
            {{ formatTimestamp(item.firstSeen) }}
          </div>
          <div v-if="returnTypeFirstLastSeen" class="e-flex e-justify-center">
            {{ formatTimestamp(item.lastSeen) }}
          </div>
          <div class="e-truncate e-flex e-justify-center">{{ item.label }}</div>
          <div class="e-flex e-justify-center">{{ item.trackId }}</div>
        </div>

        <div v-if="isLoading">
          <div
            class="e-flex e-w-full e-p-6 e-m-6 e-items-center e-justify-center e-h-full e-text-gray-300"
            style="min-height: 500px"
          >
            <EvercamLoadingAnimation />
          </div>
        </div>
        <div
          v-if="!items.length && !isLoading"
          class="e-flex e-w-full e-p-6 e-m-6 e-items-center e-justify-center e-h-full e-text-gray-300"
        >
          No data to show
        </div>
      </div>

      <!-- Pagination Footer -->
      <div
        v-if="items.length"
        class="pagination e-border-t e-border-solid e-border-gray-200 e-flex e-justify-between e-items-center e-px-4 e-py-1 e-bg-gray-100 e-font-bold e-text-gray-700 e-sticky e-bottom-0"
      >
        <div class="e-flex e-items-center">
          <span class="e-text-sm e-text-gray-700">
            Showing {{ startIndex + 1 }}-{{ endIndex }} of
            {{ totalItems }} results
          </span>
        </div>
        <div class="e-flex e-items-center e-space-x-2">
          <button
            :disabled="page === 1"
            class="e-px-3 e-py-1 e-rounded-md e-border e-text-sm"
            :class="
              page === 1
                ? 'e-opacity-50 e-cursor-not-allowed'
                : 'e-hover:e-bg-gray-50'
            "
            @click="handlePageChange(page - 1)"
          >
            Previous
          </button>
          <div class="e-flex e-space-x-1">
            <button
              v-for="p in visiblePages"
              :key="p"
              class="e-px-3 e-py-1 e-rounded-md e-border e-text-sm"
              :class="
                p === page
                  ? '!e-bg-blue-50 !e-border-blue-500 !e-text-blue-600'
                  : 'e-hover:!e-bg-gray-50'
              "
              @click="handlePageChange(p)"
            >
              {{ p }}
            </button>
          </div>
          <button
            :disabled="page >= totalPages"
            class="e-px-3 e-py-1 e-rounded-md e-border e-text-sm"
            :class="
              page >= totalPages
                ? 'e-opacity-50 e-cursor-not-allowed'
                : 'e-hover:e-bg-gray-50'
            "
            @click="handlePageChange(page + 1)"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import SmartSearchThumbnailPreview from "./SmartSearchThumbnailPreview.vue"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import { SmartSearchQueryReturnType } from "@evercam/shared/types"
import { useSmartSearchStore } from "@/stores/smartSearch"
export default {
  name: "SmartSearchResultsTable",
  components: {
    SmartSearchThumbnailPreview,
    EvercamLoadingAnimation,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    totalItems: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      height: undefined,
    }
  },
  computed: {
    ...mapStores(useAccountStore, useSmartSearchStore),
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    SmartSearchQueryReturnType() {
      return SmartSearchQueryReturnType
    },
    visiblePages() {
      const window = 3
      let start = Math.max(1, this.page - window)
      let end = Math.min(this.totalPages, this.page + window)

      const totalVisible = window * 2 + 1
      if (end - start + 1 < totalVisible) {
        if (start === 1) {
          end = Math.min(totalVisible, this.totalPages)
        } else if (end === this.totalPages) {
          start = Math.max(1, this.totalPages - totalVisible + 1)
        }
      }

      return Array.from({ length: end - start + 1 }, (_, i) => start + i)
    },
    returnTypeAll() {
      return this.smartSearchStore.returnType === SmartSearchQueryReturnType.All
    },
    returnTypeFirstLastSeen() {
      return (
        this.smartSearchStore.returnType ===
        SmartSearchQueryReturnType.FirstLastSeen
      )
    },
    startIndex() {
      return this.itemsPerPage * (this.page - 1)
    },
    endIndex() {
      return Math.min(this.itemsPerPage * this.page, this.totalItems)
    },
    containerStyles() {
      if (!this.height) {
        return
      }

      return {
        height: `${this.height}px`,
        maxHeight: `${this.height}px`,
      }
    },
  },
  mounted() {
    this.setHeight()
  },
  methods: {
    getKey(item) {
      if (this.returnTypeFirstLastSeen) {
        return `${item.firstSeen}-${item.lastSeen}-${item.trackId}`
      }

      return `${item.trackId}-${item.timestamp}`
    },
    setHeight() {
      const containerRect = this.$refs.container?.getBoundingClientRect()
      this.height = window.innerHeight - containerRect.top
    },
    formatTimestamp(timestamp) {
      if (!timestamp) return "—"

      return this.$moment(timestamp).format("ddd, DD MMMM YYYY, hh:mm:ss A")
    },
    handlePageChange(page) {
      this.$emit("page-change", page)
    },
  },
}
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";
@import "~@evercam/shared/styles/mixins";
.first-last-seen-table-grid {
  grid-template-columns: repeat(2, minmax(0, 10.6rem)) repeat(4, minmax(0, 1fr));
}
.all-table-grid {
  grid-template-columns: minmax(0, 1.5fr) repeat(3, minmax(0, 1fr));
}
.grid-table {
  display: grid;
  z-index: 3;
  padding: 0;
  border: 1px solid #d8dce1;
  background: rgba(241, 243, 246, 0.35);
  border-radius: 0.5rem;
  margin-top: 0.65rem;

  * {
    font-size: 14px;
  }

  &__container {
    &::before {
      content: "";
      position: absolute;
      display: block;
      top: -1px;
      left: 0;
      width: 100%;
      background: white;
      height: 0.5rem;
    }
  }
  &__headers {
    background: rgb(229, 231, 235);
    border: 1px solid #d3d3d3;
    & > div {
      padding: 0.35rem 0;
    }
  }

  &__body {
    &::after {
      content: "";
      position: sticky;
      display: block;
      bottom: -1px;
      left: 0;
      width: 100%;
      background: white;
      height: 0.5rem;
    }
    & > div {
      &:hover {
        background: aliceblue;
      }
    }
  }

  &:hover {
    background-color: #f9fafb;
    transition: background-color 0.2s ease;
  }
}

.pagination {
  position: sticky;
  z-index: 1;
  bottom: 0;
  padding: 0.1rem 1rem;
  padding-bottom: 0;
  border: 1px solid #d3d3d3;
  border-radius: 0.4rem;
  background: rgb(229, 231, 235);
}

.main-container {
  @include custom-scrollbar(#5d5d5d, #9b9b9b, #333);
}
</style>
