<template>
  <ECard
    class="smart-search__label"
    :class="{
      'smart-search__label--selectable': selectable,
      'smart-search__label--highlighted': highlighted,
      'smart-search__label--dense': dense,
    }"
    :image="imagePath ? require(`~/assets/img/${imagePath}`) : ''"
    :title="formattedLabel"
    blur-background
    :image-padding="dense ? 0 : 5"
    :image-size="dense ? 35 : 65"
    :image-translation="0"
    :height="dense ? 35 : 67"
    @click.native="onOptionClicked"
  >
    <template #title>
      <div
        class="smart-search__label__title e-flex e-items-center"
        :class="{ 'e-truncate': dense }"
      >
        {{ formattedLabel }}
      </div>
    </template>
    <v-btn
      v-if="deletable"
      icon
      color="error"
      class="ml-2"
      @click.stop="onDeleteOption"
    >
      <v-icon>fa-trash</v-icon></v-btn
    >
  </ECard>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import {
  BBox,
  DetectionLabel,
  SmartSearchCondition,
  SmartSearchConditionData,
  SmartSearchQueryComponentType,
} from "@evercam/shared/types"

export default Vue.extend({
  name: "SmartSearchOption",
  props: {
    value: {
      type: [String, Object, Array] as PropType<
        DetectionLabel | SmartSearchCondition | BBox
      >,
      required: true,
    },
    type: {
      type: String as PropType<SmartSearchQueryComponentType>,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imagePath(): string {
      if (this.type === SmartSearchQueryComponentType.Object) {
        return `objects/${this.value}.png`
      } else if (this.type === SmartSearchQueryComponentType.Condition) {
        if (this.value === SmartSearchCondition.InArea) {
          return "conditions/in_area.png"
        } else if (this.value === SmartSearchCondition.Time) {
          return "conditions/time.png"
        } else if (this.value === SmartSearchCondition.Intersects) {
          return "conditions/intersects.png"
        } else if (typeof this.value === "string") {
          return `conditions/${this.value}.png`
        }
      }

      if (typeof this.type === "string") {
        if (this.type === SmartSearchQueryComponentType.Area) {
          return "conditions/in_area.png"
        } else if (this.type === SmartSearchQueryComponentType.Time) {
          return "conditions/time.png"
        }
      }

      if (
        this.value &&
        typeof this.value === "object" &&
        this.value !== null &&
        "condition" in this.value
      ) {
        if (this.value.condition === SmartSearchCondition.InArea) {
          return "conditions/in_area.png"
        } else if (this.value.condition === SmartSearchCondition.Time) {
          return "conditions/time.png"
        }
      }

      return ""
    },
    formattedLabel(): string {
      if (
        this.type === SmartSearchQueryComponentType.Time ||
        (typeof this.value === "object" &&
          this.value !== null &&
          !Array.isArray(this.value) &&
          ("monday" in this.value || "tuesday" in this.value))
      ) {
        return this.formatTimeSchedule(this.value)
      }
      if (Array.isArray(this.value)) {
        return this.formatBBox(this.value as BBox)
      }

      if (
        this.value &&
        typeof this.value === "object" &&
        this.value !== null &&
        "condition" in this.value
      ) {
        const conditionData = this.value as SmartSearchConditionData

        if (conditionData.condition === SmartSearchCondition.Time) {
          return "Time Schedule"
        } else if (conditionData.condition === SmartSearchCondition.InArea) {
          return "In Area"
        } else if (
          conditionData.condition === SmartSearchCondition.Intersects
        ) {
          return "Near"
        }
      }

      if (this.value === SmartSearchCondition.Time) {
        return "Time Schedule"
      } else if (this.value === SmartSearchCondition.InArea) {
        return "In area"
      } else if (this.value === SmartSearchCondition.Intersects) {
        return "Near"
      }

      return this.formatLabel(String(this.value))
    },
  },
  methods: {
    formatBBox(bbox: BBox): string {
      const coordsString = `[${bbox.map((n) => n.toFixed(2)).join(", ")}]`

      if (this.dense) {
        return coordsString.substring(0, 12) + "...]"
      } else {
        return coordsString
      }
    },
    formatLabel(label: string): string {
      return label
        .split(/[-_]/gm)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    },
    onOptionClicked() {
      this.$emit("change", this.value)
    },
    onDeleteOption(event) {
      event.stopPropagation()
      this.$emit("delete-option", this.value)
    },
    formatTimeSchedule(schedule) {
      if (!schedule) {
        return "Time Schedule"
      }

      const days = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ]
      const shortDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

      let result = []
      let currentGroup = null
      let currentTimeRange = ""

      days.forEach((day, index) => {
        const timeRanges = schedule[day] || []
        const timeRangeStr = timeRanges.join(", ")

        if (timeRangeStr === "") {
          if (currentGroup !== null) {
            result.push(
              this.formatDayGroup(currentGroup, shortDays, currentTimeRange)
            )
            currentGroup = null
          }
        } else if (timeRangeStr === currentTimeRange) {
          if (currentGroup === null) {
            currentGroup = [index]
          } else {
            currentGroup.push(index)
          }
        } else {
          if (currentGroup !== null) {
            result.push(
              this.formatDayGroup(currentGroup, shortDays, currentTimeRange)
            )
          }

          currentGroup = [index]
          currentTimeRange = timeRangeStr
        }
      })

      if (currentGroup !== null) {
        result.push(
          this.formatDayGroup(currentGroup, shortDays, currentTimeRange)
        )
      }

      return result.join(", ") || "No Schedule"
    },

    formatDayGroup(dayIndices, shortDays, timeRange) {
      if (dayIndices.length === 0) return ""

      if (dayIndices.length === 1) {
        return `${shortDays[dayIndices[0]]} ${timeRange}`
      }

      const isConsecutive = dayIndices.every(
        (val, i, arr) => i === 0 || val === arr[i - 1] + 1
      )

      if (isConsecutive) {
        return `${shortDays[dayIndices[0]]}-${
          shortDays[dayIndices[dayIndices.length - 1]]
        } ${timeRange}`
      } else {
        return dayIndices.map((i) => shortDays[i]).join(",") + " " + timeRange
      }
    },
  },
})
</script>

<style lang="scss">
.smart-search__label {
  cursor: pointer;
  transition: transform 0.1s, background 0.1s, box-shadow 0.1s;
  box-shadow: 0 0 0 0 #00000070;

  &--selectable:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 9px -8px #00000070;
  }

  &--highlighted {
    overflow: hidden;
    background: #1976d255;
    .e-card__title {
      color: #0060b7;
    }

    .e-card__img__container {
      background: #d2e5f5;
    }
  }

  .e-card__body {
    align-items: center;
    display: flex;
    height: 100%;
  }
  .e-card__title {
    margin: 0;
  }
  .e-card__content {
    padding: 0 !important;
  }
  .e-card__img__container {
    border: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transform: none !important;
  }
  &__title {
    font-size: 14px;
  }

  &--dense {
    padding-right: 7px;
    &,
    .e-card__img__container {
      border-radius: 0.3rem;
    }
    .smart-search__label__title {
      font-size: 10px;
    }
    .e-card__title {
      padding-left: 28px !important;
    }
  }
}
</style>
